import AppModal from '@components/AppModal'

interface Props {
  cleanupSummary: any
  onClose: () => void
}

const CleanupSummaryModal = ({ cleanupSummary, onClose }: Props) => {
  return (
    <AppModal title="Cleanup summary" onCancel={onClose} open={true}>
      <div>
        <div className="mb-2">Blank Pages: {cleanupSummary.emptyPages.toString()}</div>
        <ul className="">
          Duplicate Pages:{' '}
          {cleanupSummary.duplicatedPages.map(group => {
            const first = group.shift()
            return (
              <li>
                {group.join(', ')} is duplicated with page {first}
              </li>
            )
          })}
        </ul>
      </div>
    </AppModal>
  )
}

export default CleanupSummaryModal
