import { Button } from 'antd'
import cx from 'classnames'
import styled from 'styled-components'

const ButtonWrapper = styled(Button)`
  &.text-button {
    padding: 0 !important;
    height: fit-content !important;
  }
  &.text-button:hover {
    background-color: inherit !important;
    box-shadow: inherit !important;
  }

  padding: 0 8px !important;
`
const ButtonText = ({ children, onClick = null, disabled = false, title = undefined, className = '', style = {} }) => {
  return (
    <ButtonWrapper
      type="text"
      onClick={onClick}
      title={title}
      className={cx(className)}
      disabled={disabled}
      style={style}
      size={'small'}
    >
      {children}
    </ButtonWrapper>
  )
}

export default ButtonText
